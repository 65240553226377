@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Light'), local('OpenSans-Light'), url(/assets/opensanslight.aac07fc6.woff2) format('woff2'), url(/assets/opensanslight.9a0cfeda.woff) format('woff'), url(/assets/opensanslight.25ad8d1b.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url(/assets/opensanslightitalic.3b472a01.woff2) format('woff2'), url(/assets/opensanslightitalic.03fa0fb4.woff) format('woff'), url(/assets/opensanslightitalic.161f7fcb.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), local('OpenSans'), url(/assets/opensans.48aa7ad9.woff2) format('woff2'), url(/assets/opensans.811c8bae.woff) format('woff'), url(/assets/opensans.d2a705b5.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(/assets/opensansitalic.fbf7f082.woff2) format('woff2'), url(/assets/opensansitalic.576f55c3.woff) format('woff'), url(/assets/opensansitalic.bf2f6b57.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(/assets/opensanssemibold.86dd4197.woff2) format('woff2'), url(/assets/opensanssemibold.299b3be8.woff) format('woff'), url(/assets/opensanssemibold.483351b6.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'), url(/assets/opensanssemibolditalic.1e789222.woff2) format('woff2'), url(/assets/opensanssemibolditalic.09c3b5a4.woff) format('woff'), url(/assets/opensanssemibolditalic.9e4f913f.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(/assets/opensansbold.313824e8.woff2) format('woff2'), url(/assets/opensansbold.6f05808b.woff) format('woff'), url(/assets/opensansbold.b87efff0.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url(/assets/opensansbolditalic.5f80e2b1.woff2) format('woff2'), url(/assets/opensansbolditalic.90b8cd14.woff) format('woff'), url(/assets/opensansbolditalic.47cf698e.ttf) format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url(/assets/opensansextrabold.0f5ecd4c.woff2) format('woff2'), url(/assets/opensansextrabold.6794db01.woff) format('woff'), url(/assets/opensansextrabold.a4e0d32f.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Extrabold Italic'), local('OpenSans-ExtraboldItalic'), url(/assets/opensansextrabolditalic.51d4b21e.woff2) format('woff2'), url(/assets/opensansextrabolditalic.cb00b819.woff) format('woff'), url(/assets/opensansextrabolditalic.41274fc2.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}
